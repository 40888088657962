import { captureException } from '@sentry/node';
import { sha256 } from 'js-sha256';
import { v4 as uuidv4 } from 'uuid';

interface UserData {
  fn: string[];
  ln: string[];
  em: string[];
  ph: string[];
  client_user_agent: string;
  client_ip_address: string;
}

export interface CustomerData {
  msg: string;
  scheduleTime: string;
  scheduleDate: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}

export interface FormSendingData {
  event_name: string;
  event_id: string;
  event_time: number;
  action_source: string;
  event_source_url: string;
  user_data: UserData;
}

const createSendingData = async (
  values: CustomerData,
  eventId: string,
  eventName: string
): Promise<FormSendingData> => {
  const userIp = await fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => data.ip)
    .catch(error => console.error('Error:', error));

  return {
    event_name: eventName,
    event_id: eventId,
    event_time: Math.floor(Date.now() / 1000),
    action_source: 'website',
    event_source_url: window.location.href,
    user_data: {
      fn: [sha256(values.name)],
      ln: [sha256(values.name)],
      em: [sha256(values.email)],
      ph: [sha256(values.phone)],
      client_user_agent: navigator.userAgent,
      client_ip_address: userIp || '0.0.0.0',
    },
  };
};

export default async function triggerApiMeta(values: CustomerData, eventName: string) {
  const eventId: string = uuidv4();

  const sendData = await createSendingData(values, eventId, eventName);

  fetch(
    `https://graph.facebook.com/v18.0/${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}/events?access_token=${process.env.NEXT_PUBLIC_FBACCESSKEY}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: [sendData],
      }),
    }
  )
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error, values));
}
export const ta = (event: string, eventType: string, extra?: any) => {
  if (window.ta != null) {
    try {
      window.ta(event, eventType);
    } catch (e) {
      captureException(e);
    }
  }
};

export const fbq = async (event: string, eventType: string, extra?: any) => {
  if (window.fbq != null) {
    try {
      window.fbq(event, eventType, extra);
    } catch (e) {
      captureException(e);
    }
  }
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log(`fbq('${event}', '${eventType}', ${JSON.stringify(extra)})`);
  // }
};

// Whenever a users opens any page
export const trackView = (extra?: any) => {
  fbq('track', 'ViewContent', extra);
};

// Whenever a user opens a realty page
export const trackRealty = (id: string) => {
  trackView({
    content_ids: id,
    content_type: 'home_listing',
  });
};

// Whenever a user finishes registering
export const trackRegistration = (extra?: any) => {
  fbq('track', 'CompleteRegistration');
};

// Whenever a user searchs something
export const trackSearch = (search: string) => {
  fbq('track', 'Search', {
    search_string: search,
    content_type: 'home_listing',
  });
};

// Whenever a user sends a contact message or opens the whatsapp or phone of a advertiser
export const trackContact = () => {
  fbq('track', 'Contact');
  ta('send', 'lead');
};

// Whenever a user sends the first advertise form (lead)
export const trackCMLead = () => {
  fbq('track', 'Lead');
  ta('send', 'lead');
};

export const trackFbConversion = (values: CustomerData, event: string) => {
  triggerApiMeta(values, event);
};
